import { AgeRestriction } from '../AgeRestriction';

export const ageToRestriction = (age: number | undefined): AgeRestriction | undefined => {
	if (age === undefined) {
		return undefined;
	} else if (age < 18) {
		return '<18';
	} else if (age < 65) {
		return '>18&<65';
	} else {
		return '>65';
	}
};
