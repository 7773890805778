import { build } from '../build';

export const patientsQuickFilterLsKey = 'patients-qf';
export const hamsUserInfoLsKey = 'hams-auth-user';
export const hamsLocationIdLsKey = 'hams-auth-loc';
export const suppliersQueryParamsLsKey = 'hams-suppliers-qp-' + build;
export const companiesQueryParamsLsKey = 'hams-companies-qp-' + build;
export const entPhysiciansQueryParamsLsKey = 'hams-entphys-qp-' + build;
export const generalPractitionersQueryParamsLsKey = 'hams-genpracts-qp-' + build;
export const hifsMetaQueryParamsLsKey = 'hams-hifs-metas-qp-' + build;
export const tendersQueryParamsLsKey = 'hams-tenders-qp-' + build;
export const noahDeviceInventoryItemMapsWithUsagesQueryParamsLsKey = 'hams-noah-device-with-usages-qp-' + build;
export const noahDeviceInventoryItemMapsQueryParamsLsKey = 'hams-noah-device-ii-maps-qp-' + build;
export const noahDeviceReadOutsQueryParamsLsKey = 'hams-noah-devices-ro-qp-' + build;
export const salesQueryParamsLsKey = 'hams-sales-qp-' + build;
export const dailyReceiptsQueryParamsLsKey = 'hams-daily-receipts-qp-' + build;
export const cashRegisterEntriesQueryParamsLsKey = 'hams-cash-register-entries-qp-' + build;
export const purchasesQueryParamsLsKey = 'hams-purchases-qp-' + build;
export const purchasesUblQueryParamsLsKey = 'hams-purchases-ubl-buffer-qp-' + build;
export const customerAccountsQueryParamsLsKey = 'hams-customer-accounts-qp-' + build;
export const supplierAccountsQueryParamsLsKey = 'hams-supplier-accounts-qp-' + build;
export const settlementProposalsQueryParamsLsKey = 'hams-settlement-proposals-qp-' + build;
export const unhandledCustomerAccountLinesQueryParamsLsKey = 'hams-u-cals-qp-' + build;
export const revenuesQueryParamsLsKey = 'hams-revenues-qp-' + build;
export const productCatalogQueryParamsLsKey = 'hams-productcat-qp-' + build;
export const stockItemDtosQueryParamsLsKey = 'hams-stockitem-dtos-qp-' + build;
export const stockEntriesQueryParamsLsKey = 'hams-stockentries-qp-' + build;
export const inventoryItemsQueryParamsLsKey = 'hams-invitems-qp-' + build;
export const patientsQueryParamsLsKey = 'hams-patients-qp-' + build;
export const rosaPatientsQueryParamsLsKey = 'hams-rosa-patients-qp-' + build;
export const agendaQueryParamsLsKey = 'hams-agenda-qp-' + build;
export const rosaEventsQueryParamsLsKey = 'hams-rosa-events-qp-' + build;
export const workscheduleEntriesQueryParamsLsKey = 'hams-workschedule-entries-qp-' + build;
export const documentsQueryParamsLsKey = 'hams-docs-qp-' + build;
export const failedEventsQueryParamsLsKey = 'hams-failed-events-qp-' + build;
export const eventsQueryParamsLsKey = 'hams-events-qp-' + build;
export const subscriptionsQueryParamsLsKey = 'hams-subscriptions-qp-' + build;
export const loansQueryParamsLsKey = 'hams-loans-qp-' + build;
export const ordersQueryParamsLsKey = 'hams-orders-qp-' + build;
export const articlesInquiriesQueryParamsLsKey = 'hams-articles-inquiries-qp-' + build;
export const deliveriesQueryParamsLsKey = 'hams-deliveries-qp-' + build;
export const returnsQueryParamsLsKey = 'hams-returns-qp-' + build;
export const stockMovementsQueryParamsLsKey = 'hams-stockmov-qp-' + build;
export const repairsQueryParamsLsKey = 'hams-repairs-qp-' + build;
export const rfiasQueryParamsLsKey = 'hams-rfia-qp-' + build;
export const tailorMadeProductsQueryParamsLsKey = 'hams-tmp-qp-' + build;
export const locationsQueryParamsLsKey = 'hams-locations-qp-' + build;
export const paymentMethodsQueryParamsLsKey = 'hams-payment-methods-qp-' + build;
export const templatesQueryParamsLsKey = 'hams-templates-qp-' + build;
export const usersQueryParamsLsKey = 'hams-users-qp-' + build;
export const registeredClientsQueryParamsLsKey = 'hams-registered-clients-qp-' + build;
export const allCommissionsQueryParamsLsKey = 'hams-all-commissions-qp-' + build;
export const myCommissionsQueryParamsLsKey = 'hams-my-commissions-qp-' + build;
export const allCommissionPayoutsQueryParamsLsKey = 'hams-all-commissions-p-qp-' + build;
export const myCommissionPayoutsQueryParamsLsKey = 'hams-my-commissions-p-qp-' + build;
export const treatmentsQueryParamsLsKey = 'hams-treatments-qp-' + build;
export const apiTokensQueryParamsLsKey = 'hams-apitokens-qp-' + build;

export const agendaViewLsKey = 'agenda-view';
export const agendaTimeScaleLsKey = 'agenda-time-scale';
export const agendaColorSchemeLsKey = 'agenda-color-scheme';
export const agendaGroupSchemeLsKey = 'agenda-group-scheme';
export const agendaSelectedEventTypeKeysLsKey = 'agenda-selected-event-type-keys';
export const agendaSelectedUserKeysLsKey = 'agenda-selected-user-keys';
export const agendaSelectedRoomKeysLsKey = 'agenda-selected-room-keys';

export const workscheduleViewLsKey = 'workschedule-view-type';
export const workscheduleColorSchemeLsKey = 'workschedule-color-scheme';
export const workscheduleGroupSchemeLsKey = 'workschedule-group-scheme';
export const workscheduleSelectedUserKeysLsKey = 'workschedule-selected-user-keys';
export const workscheduleSelectedRoomKeysLsKey = 'workschedule-selected-room-keys';

export const ExactOnlineCustomerAccountsQueryParamsLsKey = 'hams-eo-customer-accounts-qp-' + build;
export const ExactOnlineProductCatalogItemsQueryParamsLsKey = 'hams-eo-product-catalog-items-qp-' + build;
export const ExactOnlineSalesQueryParamsLsKey = 'hams-eo-sales-qp-' + build;
export const ExactOnlineSelectedTabKey = 'hams-eo-sel-tab-' + build;

export const InvoiceForwardingSelectedTabKey = 'hams-invoice-forw-sel-tab' + build;
export const InvoiceForwardingSalesQueryParamsLsKey = 'hams-invoice-forw-sales-qp' + build;

export const RosaIntegrationSelectedTabKey = 'hams-rosa-int-sel-tab-' + build;

export const NoahUserSetupsQueryParamsLsKey = 'hams-noah-user-setups-qp-' + build;
