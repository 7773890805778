import { Card, CardContent, CardProps } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { isLast } from 'framework/utils/array/isLast';
import { IOrderLineV2, IOrderV2, ordersCommand_deleteLine, ordersQuery_detailLines } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddOrderLinesRequestForm } from '../../forms/addLines/AddOrderLinesRequestForm';
import { OrderLineListItem } from './OrderLineListItem';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';

interface IProps extends CardProps, IReloadTriggerProps {
	item: IOrderV2;
	reload: VoidFunction;
}

export const OrderDetailContentsCard = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, reloadLines] = useApiEffect(ordersQuery_detailLines, item.id);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadLines();
	}, [reloadTrigger]);

	const onAddLines = () => {
		open(
			<AddOrderLinesRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onDeleteLine = (line: IOrderLineV2) => {
		open(
			<AlertDialog
				open
				title={strings.deleteLine}
				content={strings.doYouWantToDeleteThisLineFromThisOrderQuestion}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => ordersCommand_deleteLine({ orderId: item.id, orderLineId: line.id })}
				accept={confirm}
			/>
		);
	};

	if (lines === undefined) {
		return <></>;
	}

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					title={strings.contents}
					style={{ marginBottom: 8 }}
				/>
				<TextLinkButton
					startIcon={<AddCircleOutlineIcon />}
					onClick={onAddLines}
					color='primary'
					disabled={item.canAddOrDeleteLines === false}>
					{strings.addLines}
				</TextLinkButton>
				<DividerWithMargin />
				{lines.map(t => (
					<OrderLineListItem
						order={item}
						line={t.data}
						inventoryItem={t.inventoryItem}
						key={t.data.id}
						withoutBorder={isLast(t, lines)}
						onDelete={() => onDeleteLine(t.data)}
					/>
				))}
				{item.lines.length === 0 && <em>{strings.noLinesDefinedYet}</em>}
			</CardContent>
		</Card>
	);
};
