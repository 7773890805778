import { Divider, List, Menu, MenuItem } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { useLoggedInUserIsSuperUserOrHasClientClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasClientClaim';
import * as contactsRoutes from 'app/main/contacts/routes';
import * as financialsRoutes from 'app/main/financial/routes';
import * as inventoryManagementRoutes from 'app/main/inventoryManagement/routes';
import * as processRoutes from 'app/main/processes/ProcessesRoutes';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { LanguageOption, LanguageOptions } from 'gen/ApiClient';
import { languageRecord } from 'localization/languageRecord';
import { SpecificLocalizationContext } from 'localization/SpecificLocalizationContext';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as routes from 'routes';
import { NavigationLinkListItemButton } from 'shared/NavigationLinkListItemButton';
import { Logo } from '../logo/Logo';
import { NestedNavLink } from './NestedNavLink';
import { PrimaryNavLink } from './PrimaryNavLink';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import * as integrationsRoutes from 'app/main/integrations/IntegrationsRoutes';

type SubNavigationPane = 'processes' | 'inventory-management' | 'financial' | 'contacts' | 'settings' | 'calendar' | 'integrations' | undefined;

interface IProps {
	onLinkClicked: VoidFunction;
}

export const SideNav = ({ onLinkClicked }: IProps) => {
	const strings = useLocalization();
	const [anchorLanguage, openLanguage, closeLanguage] = useAnchorElement();
	const [selectedNested, setSelectedNested] = useState<SubNavigationPane>(undefined);
	const history = useHistory();
	const openNewTab = useOpenInNewTab();
	const localizationContext = useContext(SpecificLocalizationContext);
	const user = useLoggedInUser();
	const canViewArticlesInquiries = useLoggedInUserIsSuperUserOrHasClientClaim('ais');

	const nav = (route: string) => {
		history.push(route);
		setSelectedNested(undefined);
		onLinkClicked();
	};

	const onClickNavigationPane = (type: SubNavigationPane) => {
		if (selectedNested === type) {
			setSelectedNested(undefined);
		} else {
			setSelectedNested(type);
		}
	};

	const setLanguage = (lang: LanguageOption) => {
		localizationContext.setLanguage(lang);
		closeLanguage();
	};

	return (
		<>
			<Menu
				anchorEl={anchorLanguage}
				open={Boolean(anchorLanguage)}
				onClose={closeLanguage}
				keepMounted>
				{LanguageOptions.map(t => (
					<MenuItem
						key={t}
						onClick={() => setLanguage(t)}
						value={t}>
						{languageRecord[t]}
					</MenuItem>
				))}
			</Menu>
			<div
				className='df-col'
				style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
				<Logo style={{ paddingLeft: '24px', paddingRight: '12px', marginTop: '17px', marginBottom: '17px' }} />
				<Divider style={{ width: '100%' }} />
			</div>
			<List
				className='df-col fg1'
				style={{ padding: '12px 0px' }}>
				<PrimaryNavLink
					startIcon={<DashboardOutlinedIcon />}
					text={strings.dashboard}
					onNavigate={nav}
					route={routes.DashboardRoute}
					pageClaim='Dashboard'
				/>
				<PrimaryNavLink
					startIcon={<TodayOutlinedIcon />}
					text={strings.agenda}
					onNavigate={nav}
					route={routes.CalendarRoute}
					pageClaim='Calendar'
				/>
				<PrimaryNavLink
					startIcon={<PeopleAltOutlinedIcon />}
					text={strings.patients}
					onNavigate={nav}
					route={routes.PatientsRoute}
					pageClaim='Patients'
				/>
				<br />
				<NestedNavLink
					startIcon={<DoubleArrowIcon />}
					text={strings.processes}
					matchOnRoute={routes.ProcessesRoute}
					isOpen={selectedNested === 'processes'}
					onClickTitle={() => onClickNavigationPane('processes')}
					onNavigate={nav}
					subLinks={[
						{ route: processRoutes.ArticlesInquiriesRoute, text: strings.articlesInquiries, pageClaim: 'Orders', hidden: canViewArticlesInquiries === false },
						{ route: processRoutes.OrdersRoute, text: strings.orders, pageClaim: 'Orders' },
						{ route: processRoutes.RepairsRoute, text: strings.repairs, pageClaim: 'Repairs' },
						{ route: processRoutes.RequestsForInsuranceAllowanceRoute, text: strings.requestForInsuranceAllowancesShortened, pageClaim: 'Rfias' },
						{ route: processRoutes.LoansRoute, text: strings.loans, pageClaim: 'Loans' },
					]}
				/>
				<NestedNavLink
					startIcon={<StoreOutlinedIcon />}
					text={strings.inventoryManagementShortened}
					matchOnRoute={routes.InventoryManagementRoute}
					isOpen={selectedNested === 'inventory-management'}
					onClickTitle={() => onClickNavigationPane('inventory-management')}
					onNavigate={nav}
					subLinks={[
						{ route: inventoryManagementRoutes.ProductCatalogRoute, text: strings.productCatalog, pageClaim: 'ProductCatalog' },
						{ route: inventoryManagementRoutes.InventoryItemsRoute, text: strings.serialNumbers, pageClaim: 'InventoryItems' },
						{ route: inventoryManagementRoutes.StockRoute, text: strings.stock, pageClaim: 'Stock' },
						{ route: inventoryManagementRoutes.StockEntriesRoute, text: `${strings.history} | ${strings.evolution}`, pageClaim: 'StockEntries' },
						{ route: inventoryManagementRoutes.StockTransactionsRoute, text: strings.stockTransactions, pageClaim: undefined },
						{ route: inventoryManagementRoutes.NoahDevicesRoute, text: strings.devicesFromNoah, pageClaim: 'NoahDevices' },
						{ route: inventoryManagementRoutes.SearchSerialNumberRoute, text: strings.searchOnWhat(strings.serialNumber), pageClaim: 'SearchOnSerialNumbers' },
					]}
				/>
				<NestedNavLink
					startIcon={<AttachMoneyIcon />}
					text={strings.financial}
					onClickTitle={() => onClickNavigationPane('financial')}
					isOpen={selectedNested === 'financial'}
					matchOnRoute={routes.FinancialRoute}
					onNavigate={nav}
					subLinks={[
						{ route: financialsRoutes.TendersRoute, text: strings.tenders, pageClaim: 'Tenders' },
						{ route: financialsRoutes.SalesRoute, text: strings.sales, pageClaim: 'Sales' },
						{ route: financialsRoutes.PurchasesRoute, text: strings.purchases, pageClaim: 'Purchases' },
						{ route: financialsRoutes.CustomerAccountsRoute, text: strings.accountsReceivable, pageClaim: 'CustomerAccounts' },
						{ route: financialsRoutes.SettlementProposalsRoute, text: strings.verzamelstaten, pageClaim: 'SettlementProposals' },
						{ route: financialsRoutes.SupplierAccountsRoute, text: strings.accountsPayableShort, pageClaim: 'SupplierAccounts' },
						{ route: financialsRoutes.PaymentsRoute, text: strings.payments, pageClaim: 'Payments' },
						{ route: financialsRoutes.CommissionsRoute, text: strings.commissions, pageClaim: undefined },
						{ route: financialsRoutes.DailyReceiptsRoute, text: strings.dailyReceipts, pageClaim: 'DailyReceipts' },
						{ route: financialsRoutes.CashRegisterEntriesRoute, text: strings.cashRegisterAsLedger, pageClaim: 'CashRegisterEntries' },
					]}
				/>
				<br />
				<PrimaryNavLink
					startIcon={<ShoppingCartOutlinedIcon />}
					text={strings.cashDesk}
					route={routes.CashDeskRoute}
					onNavigate={nav}
					pageClaim='CashRegister'
				/>
				<br />
				<NestedNavLink
					startIcon={<RecentActorsOutlinedIcon />}
					text={strings.contacts}
					onClickTitle={() => onClickNavigationPane('contacts')}
					isOpen={selectedNested === 'contacts'}
					matchOnRoute={routes.ContactsRoute}
					onNavigate={nav}
					subLinks={[
						{ route: contactsRoutes.EntPhysiciansRoute, text: strings.entPhysicians, pageClaim: 'EntPhysicians' },
						{ route: contactsRoutes.GeneralPractitionersRoute, text: strings.generalPractitioners, pageClaim: 'GeneralPractitioners' },
						{ route: contactsRoutes.SuppliersRoute, text: strings.suppliers, pageClaim: 'Suppliers' },
						{ route: contactsRoutes.VatCustomersRoute, text: strings.vatCustomers, pageClaim: 'VatCustomers' },
						{ route: contactsRoutes.HifsRoute, text: strings.healthInsuranceFundShort, pageClaim: 'Hifs' },
					]}
				/>
				<NestedNavLink
					startIcon={<AccountTreeOutlinedIcon />}
					text={strings.integrations}
					onClickTitle={() => onClickNavigationPane('integrations')}
					isOpen={selectedNested === 'integrations'}
					matchOnRoute={routes.IntegrationsRoute}
					onNavigate={nav}
					subLinks={[
						{ route: integrationsRoutes.RosaRoute, text: `Rosa`, pageClaim: 'RosaIntegration' },
						{ route: integrationsRoutes.ExactOnlineRoute, text: `Exact Online`, pageClaim: 'ExactOnlineIntegration' },
						{ route: integrationsRoutes.InvoiceForwardingRoute, text: strings.salesInvoicesForwarding, pageClaim: 'InvoiceForwardingIntegration' },
					]}
				/>
				<PrimaryNavLink
					startIcon={<SettingsOutlinedIcon />}
					text={strings.settings}
					onNavigate={nav}
					route={routes.SettingsRoute}
					pageClaim={undefined}
				/>
				<div className='fg1'></div>
				{user.isSuperUser && (
					<PrimaryNavLink
						startIcon={<BugReportOutlinedIcon />}
						text={strings.troubleShoot}
						onNavigate={nav}
						route={routes.TroubleshootRoute}
						pageClaim={undefined}
					/>
				)}
				<NavigationLinkListItemButton
					startIcon={<HelpOutlineOutlined />}
					buttonStyle={{ paddingLeft: '24px' }}
					style={{ padding: 0 }}
					onClick={() => openNewTab('https://scribehow.com/page/HAMS_HELPCENTER_NL__ApZme3NCSWe5R4wNvFJmhQ', true)}>
					{'Help'}
				</NavigationLinkListItemButton>
				<NavigationLinkListItemButton
					startIcon={<LanguageIcon />}
					buttonStyle={{ paddingLeft: '24px' }}
					style={{ padding: 0 }}
					onClick={openLanguage}>
					{`${strings.language}: ${localizationContext.language.toLocaleUpperCase()}`}
				</NavigationLinkListItemButton>
			</List>
		</>
	);
};
