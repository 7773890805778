import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { HeaderTableCellX } from 'framework/table/HeaderTableCellX';
import { NoContentTableHeadX } from 'framework/table/NoContentTableHeadX';
import { SortableHeaderTableCellX } from 'framework/table/SortableHeaderTableCellX';
import { IArticlesInquiry, IQueryResult, ISortParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { ArticlesInquiryTableRow } from './ArticlesInquiryTableRow';

interface IProps {
	queryResult: IQueryResult<IArticlesInquiry>;
	sortParams: ISortParams<IArticlesInquiry>;
	onFilter: (sp: ISortParams<IArticlesInquiry>) => void;
	expandedId: string | undefined;
	expandAll: boolean;
	toggle: (id: string) => void;
}

export const ArticlesInquiriesTable = ({ queryResult, sortParams, onFilter, expandedId, expandAll, toggle }: IProps) => {
	const strings = useLocalization();

	return (
		<Table stickyHeader>
			{queryResult.unfilteredCount === 0 && <NoContentTableHeadX>{strings.noArticlesInquiriesDefinedYet}</NoContentTableHeadX>}
			{queryResult.unfilteredCount > 0 && (
				<TableHead>
					<TableRow>
						<SortableHeaderTableCellX<IArticlesInquiry>
							isFirst
							forProperty='reference'
							sortParams={sortParams}
							onFilter={onFilter}>
							{strings.reference}
						</SortableHeaderTableCellX>
						<SortableHeaderTableCellX<IArticlesInquiry>
							forProperty='created'
							sortParams={sortParams}
							onFilter={onFilter}>
							{strings.date}
						</SortableHeaderTableCellX>
						<HeaderTableCellX>{strings.patient}</HeaderTableCellX>
						<HeaderTableCellX isLast></HeaderTableCellX>
					</TableRow>
				</TableHead>
			)}
			<TableBody>
				{queryResult.values.map(t => (
					<ArticlesInquiryTableRow
						key={t.id}
						item={t}
						isExpanded={expandedId === t.id || expandAll}
						toggle={() => toggle(t.id)}
					/>
				))}
			</TableBody>
		</Table>
	);
};
