import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	articlesInquiriesQuery_concurrencyToken,
	articlesInquiriesQuery_query,
	IArticlesInquiriesMetadata,
	IArticlesInquiriesQueryParams,
	IArticlesInquiry,
	IQueryResultWithMetadata,
} from 'gen/ApiClient';
import React from 'react';
import { articlesInquiriesQueryParamsLsKey } from '../../../../localStorageKeys';
import { ArticlesInquiriesQueryContext } from './ArticlesInquiriesQueryContext';

const DefaultParams: IArticlesInquiriesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	isAssigned: undefined,
	statuses: [],
	handlingUserIds: [],
	prefilter: 'All',
};

export const ArticlesInquiriesQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<IArticlesInquiry, IArticlesInquiriesMetadata>, IArticlesInquiriesQueryParams>
			defaultParams={DefaultParams}
			queryF={articlesInquiriesQuery_query}
			concurrencyF={articlesInquiriesQuery_concurrencyToken}
			localStorageKey={articlesInquiriesQueryParamsLsKey}
			context={ArticlesInquiriesQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
