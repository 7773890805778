import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ShopTwoOutlinedIcon from '@material-ui/icons/ShopTwoOutlined';
import TimerIcon from '@material-ui/icons/Timer';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useAccordionState } from 'framework/utils/useAccordionState';
import {
	RfiaHandoverSaleGenerationMode,
	RfiaInvoiceProcessStep,
	TrialTimeUnits,
	rfiaSettingsCommand_patchDefaultExpectedReceptionTermForAdvisoryPhysician,
	rfiaSettingsCommand_patchDefaultExpectedReceptionTermForMedicalPrescription,
	rfiaSettingsCommand_patchDefaultTrialDuration,
	rfiaSettingsCommand_patchExpectedHandoverTermAfterApproval,
	rfiaSettingsCommand_patchReferenceSettings,
	rfiasQuery_settings,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReferenceSettingsForm } from 'shared/ReferenceSettingsForm';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PageReloadButton } from 'shared/layout/PageReloadButton';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RecordContext } from 'shared/records/RecordContext';
import { TimeModelForm } from 'shared/timeUnit/TimeModelForm';
import { formatTimeModel } from 'shared/timeUnit/formatTimeModel';
import { boolToYesNo } from 'shared/utils/boolToString';
import { calculateReference } from 'shared/utils/calculateReference';
import { RequestsForInsuranceAllowanceRoute } from '../../ProcessesRoutes';
import { DefaultFailedTrialVatPercentageForm } from './DefaultFailedTrialVatPercentageForm';
import { DefaultInvoiceProcessStepsForm } from './DefaultInvoiceProcessStepsForm';
import { DefaultThirdPartySchemeForm } from './DefaultThirdPartySchemeForm';
import { PatchRfiaHandoverSaleGenerationModeRequestForm } from './PatchRfiaHandoverSaleGenerationModeRequestForm';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

type AccordionType = 'terms' | 'financial' | 'numbering';

export const RfiasSettingsPage = () => {
	const strings = useLocalization();
	const [trigger, reload] = useTrigger();
	const [settings, reloadSettings] = useApiEffect(rfiasQuery_settings);
	const [expandedAccordion, setExpandedAccordion] = useAccordionState<AccordionType>();
	const { goBack } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { timeUnitRecord, rfiaInvoiceProcessStepRecord, rfiaHandoverSaleGenerationModeRecord } = React.useContext(RecordContext);

	useLazyEffect(() => {
		reloadSettings();
	}, [trigger]);

	if (settings === undefined) {
		return <div></div>;
	}

	const onUpdateDefaultFailedTrialVatPercentage = () => {
		open(
			<DefaultFailedTrialVatPercentageForm
				open
				initialValue={settings.defaultFailedTrialVatPercentage}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onUpdateDefaultDuration = () => {
		open(
			<TimeModelForm
				open
				title={strings.changeWhat(strings.defaultWhat(strings.trialDuration))}
				defaultUnit='Weeks'
				label={strings.defaultWhat(strings.trialDuration)}
				model={settings.defaultTrialDuration}
				confirm={confirm}
				submitFunction={model => rfiaSettingsCommand_patchDefaultTrialDuration({ timeModel: model })}
				cancel={cancel}
				options={TrialTimeUnits}
			/>
		);
	};

	const onReferenceSettings = () => {
		open(
			<ReferenceSettingsForm
				cancel={cancel}
				confirm={confirm}
				open
				value={settings.referenceSettings}
				title={strings.changeWhat(strings.numberingWhat(strings.requestForInsuranceAllowances))}
				submitFunction={model => rfiaSettingsCommand_patchReferenceSettings(model)}
			/>
		);
	};

	const onEditThirdPartyScheme = () => {
		open(
			<DefaultThirdPartySchemeForm
				open
				value={settings.defaultApplyThirdPartyScheme}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onUpdateSteps = () => {
		open(
			<DefaultInvoiceProcessStepsForm
				open
				value={settings.defaultSteps}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onUpdateExpectedTermReceptionMedicalPrescription = () => {
		open(
			<TimeModelForm
				open
				title={`${strings.changeWhat(strings.defaultWhat(strings.expectedWhat(strings.receptionTermOfDocuments)))} (${strings.medicalPrescription})`}
				defaultUnit='Days'
				label={strings.defaultWhat(strings.expectedWhat(strings.receptionTermOfDocuments))}
				model={settings.defaultExpectedReceptionTermForMedicalPrescription}
				confirm={confirm}
				submitFunction={model => rfiaSettingsCommand_patchDefaultExpectedReceptionTermForMedicalPrescription({ timeModel: model })}
				cancel={cancel}
				options={TrialTimeUnits}
			/>
		);
	};

	const onUpdateExpectedTermReceptionAdvisoryPhysician = () => {
		open(
			<TimeModelForm
				open
				title={`${strings.changeWhat(strings.defaultWhat(strings.expectedWhat(strings.receptionTermOfDocuments)))} (${strings.advisoryPhysician})`}
				defaultUnit='Days'
				label={strings.defaultWhat(strings.expectedWhat(strings.receptionTermOfDocuments))}
				model={settings.defaultExpectedReceptionTermForAdvisoryPhysician}
				confirm={confirm}
				submitFunction={model => rfiaSettingsCommand_patchDefaultExpectedReceptionTermForAdvisoryPhysician({ timeModel: model })}
				cancel={cancel}
				options={TrialTimeUnits}
			/>
		);
	};

	const onUpdateExpectedHandoverTerm = () => {
		open(
			<TimeModelForm
				open
				title={`${strings.changeWhat(strings.defaultWhat(strings.expectedWhat(strings.termForHandoverAfterApproval)))} (${strings.advisoryPhysician})`}
				defaultUnit='Days'
				label={strings.defaultWhat(strings.expectedWhat(strings.termForHandoverAfterApproval))}
				model={settings.defaultExpectedHandoverTermAfterApproval}
				confirm={confirm}
				submitFunction={model => rfiaSettingsCommand_patchExpectedHandoverTermAfterApproval({ timeModel: model })}
				cancel={cancel}
				options={TrialTimeUnits}
			/>
		);
	};

	const onHandoverSaleGenerationMode = () => {
		open(
			<PatchRfiaHandoverSaleGenerationModeRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				mode={settings.handoverSaleGenerationMode ?? ('AskEveryTime' as RfiaHandoverSaleGenerationMode)}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Rfias'
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<PageBreadcrumb
							text={strings.requestForInsuranceAllowances}
							route={RequestsForInsuranceAllowanceRoute}
							icon={<ShopTwoOutlinedIcon />}
						/>
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.settings} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<Button
								size='small'
								variant='outlined'
								startIcon={<CloseIcon />}
								onClick={() => goBack()}>
								{strings.closeThisWindow}
							</Button>
							<PageReloadButton reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
					maxWidth: 1280,
				}}>
				<Accordion
					expanded={expandedAccordion === 'terms'}
					onChange={() => setExpandedAccordion('terms')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.terms}</span>
					</AccordionSummary>
					<AccordionDetails className='df-col gap-8'>
						<LabelledPropertyX
							icon={<TimerIcon />}
							label={strings.trialDuration}
							edit={onUpdateDefaultDuration}>
							{formatTimeModel(settings.defaultTrialDuration, timeUnitRecord)}
						</LabelledPropertyX>
						<LabelledPropertyX
							icon={<TimerIcon />}
							label={`${strings.expectedWhat(strings.receptionTermOfDocuments)} (${strings.medicalPrescription})`}
							edit={onUpdateExpectedTermReceptionMedicalPrescription}>
							{formatTimeModel(settings.defaultExpectedReceptionTermForMedicalPrescription, timeUnitRecord)}
						</LabelledPropertyX>
						<LabelledPropertyX
							icon={<TimerIcon />}
							label={`${strings.expectedWhat(strings.receptionTermOfDocuments)} (${strings.advisoryPhysician})`}
							edit={onUpdateExpectedTermReceptionAdvisoryPhysician}>
							{formatTimeModel(settings.defaultExpectedReceptionTermForAdvisoryPhysician, timeUnitRecord)}
						</LabelledPropertyX>
						<LabelledPropertyX
							icon={<TimerIcon />}
							label={strings.expectedWhat(strings.termForHandoverAfterApproval)}
							edit={onUpdateExpectedHandoverTerm}>
							{formatTimeModel(settings.defaultExpectedHandoverTermAfterApproval, timeUnitRecord)}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expandedAccordion === 'financial'}
					onChange={() => setExpandedAccordion('financial')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.financial}</span>
					</AccordionSummary>
					<AccordionDetails className='df-col gap-8'>
						<LabelledPropertyX
							label={strings.thirdPayerArrangement}
							edit={onEditThirdPartyScheme}>
							{boolToYesNo(settings.defaultApplyThirdPartyScheme, strings)}
						</LabelledPropertyX>
						<LabelledPropertyX
							label={strings.invoiceProcess}
							edit={onUpdateSteps}>
							<div className='df-col gap-4'>
								{settings.defaultSteps.map(t => (
									<span key={t}>{`* ${rfiaInvoiceProcessStepRecord[t as RfiaInvoiceProcessStep]}`}</span>
								))}
							</div>
						</LabelledPropertyX>
						<LabelledPropertyX
							label={strings.defaultFailedTrialVatPercentage}
							edit={onUpdateDefaultFailedTrialVatPercentage}>
							{`${settings.defaultFailedTrialVatPercentage} %`}
						</LabelledPropertyX>
						<LabelledPropertyX
							label={strings.modeGenerateInvoiceAtHandover}
							edit={onHandoverSaleGenerationMode}>
							{rfiaHandoverSaleGenerationModeRecord[settings.handoverSaleGenerationMode as RfiaHandoverSaleGenerationMode]}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expandedAccordion === 'numbering'}
					onChange={() => setExpandedAccordion('numbering')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.numbering}</span>
					</AccordionSummary>
					<AccordionDetails className='df-col gap-8'>
						<LabelledPropertyX
							icon={<FormatListNumberedIcon />}
							edit={onReferenceSettings}>
							{calculateReference(settings.referenceSettings)}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
			</div>
		</RestrictedPageX>
	);
};
